import styled from 'styled-components';
import { prop } from 'styled-tools';

const ResultsTableHeader = styled.div`
        margin-top: 30px;
        margin-left: 10px;
        margin-right: 10px;
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        font-family: ${prop('font', 'inherit')}
    `;

export default ResultsTableHeader;
