import React from 'react';
import { isNil } from 'ramda';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { RESULTS_TABLE_HEADERS } from './constants';
import {
    Row, ResultsTableWrapper, ResultsTableHeader, HeaderItem, Field
} from './components';
import CouponButton from '../../components/CouponButton';
import { isListOfPlacesEmptySelector, getListOfPlacesSortedByDistanceSelector } from '../../redux/selectors';
import { showSubscribeFormModalAction } from '../SubscribeFormModal/actions';
import { websiteConfig } from '../../websiteConfigs';
import CouponLink from '../../components/CouponLink';
import CallLink from '../../components/CallableLink';

function ResultsTable() {
    const dispatch = useDispatch();
    const isListOfPlacesEmpty = useSelector(isListOfPlacesEmptySelector, shallowEqual);
    const listOfPlaces = useSelector(getListOfPlacesSortedByDistanceSelector, shallowEqual);

    if (isListOfPlacesEmpty) {
        return (
            <></>
        );
    }

    return (
        <>
            {
                !isNil(websiteConfig.resultsTableHeaderText) && (
                    <ResultsTableHeader font={websiteConfig.resultsTableHeaderFont}>{websiteConfig.resultsTableHeaderText}</ResultsTableHeader>
                )
            }
            <ResultsTableWrapper>
                <Row headerRow>
                    { RESULTS_TABLE_HEADERS.map((header) => (
                        <HeaderItem key={header}>
                            { header }
                        </HeaderItem>
                    ))}
                </Row>
                {
                    listOfPlaces.map((placeInfo) => (
                        <Row key={placeInfo.get('id')}>
                            <Field>
                                {placeInfo.get('name')}
                            </Field>
                            <Field style={{ flexDirection: 'column' }}>
                                <div>{placeInfo.get('address')}</div>
                                <div>{`${placeInfo.get('postalCode')} ${placeInfo.get('city')}` }</div>
                            </Field>
                            <Field>
                                {
                                    (isNil(placeInfo.get('phone')) || placeInfo.get('phone') === 'None')
                                        ? '-'
                                        : (<CallLink href={`tel:${placeInfo.get('phone')}`}>{placeInfo.get('phone')}</CallLink>)
                                }
                            </Field>
                            <Field>
                                {placeInfo.get('distance').toFixed(2)}
                                {' '}
                                km
                            </Field>
                            <Field>
                                {
                                    isNil(websiteConfig.popoverLinkUrl)
                                        ? (<CouponButton onClick={() => dispatch(showSubscribeFormModalAction(placeInfo.get('id')))}>bezpłatne soczewki</CouponButton>)
                                        : (<CouponLink href={websiteConfig.popoverLinkUrl} target="_blank">bezpłatne soczewki</CouponLink>)
                                }

                            </Field>
                        </Row>
                    ))
                }

            </ResultsTableWrapper>
        </>
    );
}

export default ResultsTable;
