import styled from 'styled-components';
import { theme } from 'styled-tools';

const ResultsTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    
    max-height: ${theme('dims.searchResults.height')};
    overflow-y: scroll;
    background-color: white;
    box-shadow: ${theme('shadows.materialShadow')};
    
    @media only screen and (min-width: ${theme('dims.searchResults.mediaQueries.mobile')}) {
        margin-left: ${theme('dims.searchResults.marginHorizontal.desktop')};
        margin-right: ${theme('dims.searchResults.marginHorizontal.desktop')};
    }
    
   @media only screen and (max-width: ${theme('dims.searchResults.mediaQueries.mobile')}) {
        margin-left: ${theme('dims.searchResults.marginHorizontal.mobile')};
        margin-right: ${theme('dims.searchResults.marginHorizontal.mobile')};
    }
`;

export default ResultsTableWrapper;
