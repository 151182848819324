import { prop } from 'ramda';

const websiteConfigs = {
    mojepierwszesoczewki: {
    },
    eyeoxy: {
    },
    iloox: {
        resultsTableHeaderText: 'Oto lista specjalistów lub salonów optycznych, które oferują soczewki iLoox.',
        resultsTableHeaderFont: "'Museo Sans 700'",
        popoverLinkUrl: 'http://iloox.bjdigitaldev.pl/wp-content/uploads/kupon_lokator_test.jpg'
    }
};

export const websiteConfig = prop(process.env.REACT_APP_CONTEXT, websiteConfigs);
