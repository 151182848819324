export const dims = {
    mediaQueries: {
        mobile: '500px'
    },
    searchBar: {
        inputWrapperWidth: '500px',
        inputWrapperHeight: '85px',
        inputWrapperBorderRadius: '100px',
        inputWrapperMarginHorizontal: '10px',
        searchButtonWidth: '70px',
        searchButtonHeight: '70px',
        searchButtonRightMargin: '15px',
        TextInputWrapperLeftMargin: '55px',
        TextInputWrapperLeftMarginSmallScreens: '8vw'
    },
    layout: {
        pageContentHeight: '600px',
        pageHeaderHeight: '190px',
        pageHeaderPadding: '50px 0 57px 0'
    },
    mapComponents: {
        pinImageTransformation: '-12.5px, -39px, 0',
        popupTitleMarginBottom: '15px',
        popupTitleMarginTop: '-1px',
        popoutContentBorderRadius: '8px',
        popoutContentTop: '0',
        popoutContentLeft: '0',
        geoControlMargin: '10px',
        geoControlTop: '0',
        geoControlLeft: '0',
        userLocationDotHeatWaveRight: '0',
        mapOverlayContentWrapperHeight: '500px',
        GeolocateControlWrapperTop: '0',
        GeolocateControlWrapperLeft: '0',
        GeolocateControlWrapperPadding: '10px',
        AutoResizeMapWrapperHeight: '500px',
        FullscreenControlWrapperTop: '36px',
        FullscreenControlWrapperLeft: '0',
        FullscreenControlWrapperPadding: '10px',
        pin: {
            maxWidth: '25px',
            maxHeight: '39px',
            aspectRatio: '0.64102564102'
        }
    },
    mapPopup: {
        header: {
            paddingHorizontal: '8px'
        },
        span: {
            marginHorizontal: '4px'
        }
    },
    searchResults: {
        marginHorizontal: {
            desktop: '32px',
            mobile: '8px'
        },
        height: '320px',
        row: {
            minHeight: '72px',
            paddingVertical: '5px'
        },
        mediaQueries: {
            mobile: '700px'
        }
    },
    subscribeForm: {
        mobileMediaQueryThreshold: '900px',
        backgroundOverlay: {
            zIndex: 100000
        },
        modal: {
            zIndex: 150000,
            marginVertical: '8px',
            marginHorizontal: '8px',
            width: '1200px',
            desktop: {
                height: '816px'
            },
            mobile: {
                height: '90vh'
            }
        },
        formImage: {
            width: '560px'
        },
        formContent: {
            paddingVertical: '32px',
            paddingHorizontal: '40px'
        },
        modalTitle: {
            marginTop: '80px',
            marginBottom: '27px'
        },
        textInput: {
            height: '40px',
            borderRadius: '80px',
            paddingVertical: '16px',
            marginBottom: '16px'
        },
        textInputLabel: {
            marginLeft: '20px'
        },
        textInputError: {
            marginBottom: '32px'
        },
        subscribeButton: {
            height: '40px',
            borderRadius: '80px',
            marginVertical: '5px'
        },
        legalInfo: {
            defaultMarginVertical: '16px'
        },
        checkboxLabel: {
            marginLeft: '8px',
            marginTop: '3px'
        }
    },
    couponModal: {
        width: '512px',
        desktopHeight: '96vh'
    }
};
